#mainDivHome2
{
    width: 100%;
    display: flex;
    padding-bottom: 100px;
}
#ChildDivHome2-1
{
    overflow: visible;
    width: 60%;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: start;
}
#contentHome2
{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
}
#ChildChildChildDivHome2
{
    padding: 30px;
    z-index: 20;
    width: 500px;
    background-color: #eac895;
    height: 100%;
    overflow: hidden;
}
#ChildChildDivHome2-1
{
    margin-top: -50px;
    display: flex;
    justify-content: center;
    z-index: 20;
    width: 100%;
    background-color: rgba(254, 254, 254, 0);
    height: 55%;
}
#ChildChildDivHome2-2
{
    font-size: 40px;
    font-family: 'Times New Roman';
    font-weight: 700;
    line-height:70px;
    display: flex;
    padding: 0px;
    flex-direction: column;
    justify-content: end;
    height: 50%;
    width: 100%;
}
#ChildDivHome2-2
{
    margin-top: 100px;
    display: flex;
    overflow: hidden;
    margin-left: 50px;
    padding-inline-end: 50px;
    /* justify-content: center; */
    /* margin-inline-start: 200px; */
    width: 40%;
}
#imageHome2
{
    width: 500px;
    height: 500px;
    object-fit: cover;
}
.homeContentLine
{
    text-align-last: end;
    font-size: 4vw;
    /* width: min-content; */
}


@media screen and (max-width: 950px) {
    #mainDivHome2
    {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
    }
    #ChildChildChildDivHome2
    {
        height: max-content;
        
    }
    
    #ChildDivHome2-1
    {
        height: max-content;
        width: 100%;
    }
    #ChildDivHome2-2
    {
        width: 100%;
        padding: 0px;
        margin: 0px;
        justify-content: center;
    }
    #ChildChildDivHome2-2
    {
        margin-block-start: 20px;
    }
    .homeContentLine
    {
        text-align-last: center;
        text-align: center;
        font-size: 6vw;
    }
  }


  @media screen and (max-width: 550px) {
    
    #ChildChildChildDivHome2
    {
        width: 100%;
        height: max-content;
        
    }
    #ChildChildDivHome2-2
    {
        margin-block-start: 20px;
        height: fit-content;
        padding-bottom: 20px;
    }

  }
  @media screen and (max-width: 415px) {
    #ChildChildChildDivHome2
    {
        margin-top: 0px;
    }

  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 70%);
    }
  
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  