.LogoLoader
{
    animation: logo-Loading 0.5s linear infinite alternate;
}

@keyframes logo-Loading {
    0% {
      transform: scale(1);
    }
  
    100% {
      transform: scale(1.2);
    }
  }

