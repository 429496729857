.inputLableAddProducts
{
    font-family: 'Trebuchet MS';
    color: black;
    font-size: 20px;
    font-weight: 500;
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.LoadingDivSize
{
    height: 320px;
}

.AddProductsModalTitle
{
    font-family: 'Trebuchet MS';
    width: 95%;
    font-size: 30px;
    font-weight: 800;
}

.inputTagAddProducts{
    height: 40px;
    border: none;
    border-radius: 0px;
    border-bottom: solid black 2px;
    transition: all ease-in-out 0.3s;
    font-size: 20px;
    font-family: 'Trebuchet MS';
}

.inputTagAddProducts:focus{
    outline: none;
    background-color: rgb(236, 236, 236);
}

.SelectAddProducts{
    height: 40px;
    width: 100%;
    border-radius: 0px;
    border:none;
    font-size: 20px;
    font-family: 'Trebuchet MS';
    padding: 3px;
}

.select:focus{
    outline: none;
}

.fileSelectAddProducts
{
    font-size: 20px;
    font-family: 'Trebuchet MS';
}

.buttonAddProducts
{
    background-color: black;
    border: 2px solid black;
    border-radius: 0px;
    height: 40px;
    font-size: 17px;
    font-family: 'Trebuchet MS';
    transition: all ease-in-out 0.3s;
}

.buttonAddProducts:hover{
    background-color: white;
    color: black;
    border: 2px solid black;
}
