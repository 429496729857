#navmainDiv {
  height: 70px;
  position: sticky;
  top: 0px;
  z-index: 999;
  width: 100%;
}
.nav-div {
  height: 100%;
  width: auto;
}
.nav-div-mobile {
  height: 100%;
  width: auto;
}
.nav-desktop {
  border-radius: 0px;
  background-color: #30373f00;
}
.nav-mobile {
  background-color: #30373f00;
  position: sticky;
  top: 0px;
  z-index: 999;
}
.nav-logo {
  object-fit: cover;
  height: 3rem;
}
.nav-logo-div {
  margin-left: 30px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  height: 100%;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
}

.nav-list-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.nav-list-div li {
  list-style: none;
}

.nav-list-div li .nav-list-item {
  position: relative;
  display: block;
  text-transform: uppercase;
  margin: 20px 0;
  padding: 10px 20px;
  text-decoration: none;
  font-family: sans-serif;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  transition: 0.5s;
  z-index: 1;
}

.nav-list-div li .nav-list-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 2px solid #e6af5d;
  border-bottom: 2px solid #e6af5d;
  transform: scaleY(2);
  opacity: 0;
  transition: 0.3s;
}

.nav-list-div li .nav-list-item:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e6af5d;
  transform: scale(0);
  opacity: 0;
  transition: 0.3s;
  z-index: -1;
}

.nav-list-div li .nav-list-item:hover {
  color: white;
}

.nav-list-div li .nav-list-item:hover:before {
  transform: scaleY(1);
  opacity: 1;
}

.nav-list-div li .nav-list-item:hover:after {
  transform: scaleY(1);
  opacity: 1;
}

.nav-list-div li .nav-list-item-mobile {
  position: relative;
  display: block;
  text-transform: uppercase;
  margin: 20px 30px 20px 0px;
  padding: 10px 10px;
  text-decoration: none;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 600;
  transition: 0.5s;
  z-index: 1;
}

.nav-list-div li .nav-list-item-mobile:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 2px solid #e6af5d;
  border-bottom: 2px solid #e6af5d;
  transform: scaleY(2);
  opacity: 0;
  transition: 0.3s;
}

.nav-list-div li .nav-list-item-mobile:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e6af5d;
  transform: scale(0);
  opacity: 0;
  transition: 0.3s;
  z-index: -1;
}

.nav-list-div li .nav-list-item-mobile:hover {
  color: #ffffff;
}

.nav-list-div li .nav-list-item-mobile:hover:before {
  transform: scaleY(1);
  opacity: 1;
}

.nav-list-div li .nav-list-item-mobile:hover:after {
  transform: scaleY(1);
  opacity: 1;
}

.nav-list-div {
  display: flex;
  width: 95%;
  justify-content: end;
  margin-right: 20px;
  align-items: center;
  color: #ffffff;
}

.button-nav-mobile {
  font-size: 35px;
  color: #ffffff;
  margin-right: 25px;
}
.nav-mobile-main-div {
  width: 95%;
  display: flex;
  justify-content: end;
}
#offcanvasExample
{
  width: 250px;
}

.nav-list-div-mobile {
  display: flex;
  flex-direction: column;
  width: auto;
  /* justify-content: end; */
  margin-right: 30px;
  align-items: center;
}
.mobile-canvas-body {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #30373f;
}
.mobile-canvas-head {
  background-color: #30373f;
}
.close-btn-mobile {
  color: #ffffff;
}

@media screen and (max-width: 700px) {
  .nav-desktop {
    display: none;
  }
  .nav-mobile {
    display: block;
  }
}
@media screen and (min-width: 700px) {
  .nav-desktop {
    display: blick;
  }
  .nav-mobile {
    display: none;
  }
}
